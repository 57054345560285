@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,700&display=swap");

html, body {
  /* height: 100%; */
  margin: 0;
  padding: 0;

}

body {
  background-image: url(./assets/1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
}

/* Estilos específicos para dispositivos móviles */

@media (max-width: 768px) {
  body {
    margin: 0;
    padding: 0;
    background-image: url(./assets/2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    min-height: 100vh;
  }
  .mt-20 {
    margin-top:55%!important;
  }
}


.btn {
  font-size: 1rem;
  background-color: rgb(80, 191, 80);
  color: rgb(19, 91, 163);
  font-family: "Montserrat", sans-serif;
}

.form-control {
  font-size: 1rem;
}

.mt-20 {
  margin-top: 15%;
}

.logoGreat {
  margin-top: -10px;
}
